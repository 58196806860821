import React, { useState, useEffect } from "react";
import {
  Form,
  DropdownButton,
  Dropdown,
  Collapse,
  Button,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  DateRangePicker,
  defaultStaticRanges,
  createStaticRanges,
  defaultInputRanges,
  DateRange,
} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { TextField, IconButton } from "@mui/material";
import { FaCalendarAlt } from "react-icons/fa";
import { enGB } from "date-fns/locale";
import DateRangePickerModal from "./DateRangePickerModal";
import { format } from "date-fns";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    className="custom-date-input"
    onClick={onClick}
    ref={ref}
    value={value}
    readOnly
  />
));

const ReportsFilterForm = ({
  user,
  tenantsList,
  managersList,
  customersList,
  setGroupByPeriod,
  setUsageType,
}) => {
  const { t } = useTranslation();
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [usageOpen, setUsageOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);

  const handleToggleUsageOpen = () => {
    // Toggle the clicked date picker, closing any other that might be open
    setUsageOpen(!usageOpen);
  };

  const handleToggleOrderOpen = () => {
    setOrderOpen(!orderOpen);
  };

  const filteredDefaultStaticRanges = defaultStaticRanges.filter(
    (range) =>
      range.label !== "Last Month" &&
      range.label !== "Last Week" &&
      range.label !== "Yesterday" &&
      range.label !== "This Week"
  );

  const translatedDefaultStaticRanges = filteredDefaultStaticRanges.map(
    (range) => ({
      ...range,
      label: t(`reports.${range.label}`), // Translate each label key
    })
  );

  const filteredDefaultInputRanges = defaultInputRanges.filter(
    (range) => range.label !== "days up to today"
  );

  const translatedDefaultInputRanges = filteredDefaultInputRanges.map(
    (range) => ({
      ...range,
      label: t(`reports.${range.label}`), // Translate each label key
    })
  );

  // const customStaticRanges = createStaticRanges([
  //   {
  //     label: t(`reports.Next Week`),
  //     range: () => {
  //       const today = new Date();
  //       const dayOfWeek = today.getDay();
  //       const daysUntilMonday = (8 - dayOfWeek) % 7; // Calculate days until next Monday
  //       const comingMonday = new Date(today);
  //       comingMonday.setDate(today.getDate() + daysUntilMonday);

  //       const followingSunday = new Date(comingMonday);
  //       followingSunday.setDate(comingMonday.getDate() + 6); // Set to the next Sunday after Monday

  //       return {
  //         startDate: comingMonday,
  //         endDate: followingSunday,
  //       };
  //     },
  //   },
  //   {
  //     label: t(`newTour.Next Month`),
  //     range: () => {
  //       const startDate = new Date(
  //         new Date().getFullYear(),
  //         new Date().getMonth() + 1,
  //         1
  //       );
  //       const endDate = new Date(
  //         new Date().getFullYear(),
  //         new Date().getMonth() + 2,
  //         0
  //       ); // Last day of next month

  //       return {
  //         startDate,
  //         endDate,
  //       };
  //     },
  //   },
  //   {
  //     label: t(`newTour.Next Year`),
  //     range: () => ({
  //       startDate: new Date(new Date().getFullYear() + 1, 0, 1),
  //       endDate: new Date(new Date().getFullYear() + 1, 11, 31), // 1 year from today
  //     }),
  //   },
  // ]);

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    initialValues,
    setValues,
    setFieldValue,
    handleSubmit,
  } = useFormikContext();
  const [nextCollapse, setNextCollapse] = useState(null);
  const [reportTypeCollapse, setReportTypeCollapse] = useState(null);
  const [prevReportType, setPrevReportType] = useState(null);

  const handleSelect = (item) => {
    const { startDate, endDate } = item.selection;

    setFieldValue("usageStartDate", startDate);
    setFieldValue("usageEndDate", endDate);
  };

  useEffect(() => {
    // Close all collapses
    setNextCollapse(null);

    // Open the relevant collapse after a delay
    setTimeout(() => {
      setNextCollapse(values.simSearchCriteriaType);
    }, 300);
  }, [values.simSearchCriteriaType]);

  useEffect(() => {
    // Check if transitioning between "invoiceDetail" and "invoiceOverview"
    const isInvoiceTransition =
      (values.reportType === "invoiceDetail" &&
        prevReportType === "invoiceOverview") ||
      (values.reportType === "invoiceOverview" &&
        prevReportType === "invoiceDetail");

    // If transitioning between "invoiceDetail" and "invoiceOverview", do not reset collapse
    if (!isInvoiceTransition) {
      setReportTypeCollapse(null);
      setTimeout(() => {
        setReportTypeCollapse(values.reportType);
      }, 400);
    }

    // Update the previous reportType value
    setPrevReportType(values.reportType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reportType]);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>{t("reports.reportType")}</Form.Label>
        <Form.Select
          name="reportType"
          value={values.reportType}
          onChange={(e) => {
            setValues(initialValues);
            handleChange(e);
          }}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.reportType && errors.reportType)}
        >
          <option key="usage" value="usage">
            {t("reports.usage")}
          </option>
          {/* <option key="ipAllocation" value="ipAllocation">
            IP Allocation
          </option> */}
          <option key="invoice" value="invoice">
            Invoice Detail
          </option>
          <option key="orders" value="orders">
            Orders
          </option>
        </Form.Select>
      </Form.Group>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.usageType")}</Form.Label>
          <Form.Select
            name="usageType"
            value={values.usageType}
            onChange={(e) => {
              setUsageType(e.target.value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.usageType && errors.usageType)}
          >
            <option key="" value="">
              {t("reports.selectUsageType")}
            </option>
            <option key="data" value="data">
              {t("reports.data")}
            </option>
            <option key="sms" value="sms">
              {t("reports.sms")}
            </option>
            {/* <option key="sms" value="sms">
              SMS
            </option> */}
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>
            {t("reports.groupBy")} <small>{t("reports.field")}</small>
          </Form.Label>
          <Form.Select
            name="groupByField"
            value={values.groupByField}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.groupByField && errors.groupByField)}
          >
            <option key="all" value="all">
              {t("reports.all")}
            </option>
            <option key="imsi" value="imsi">
              {t("reports.imsi")}
            </option>
            {values.usageType === "data" && (
              <option key="network" value="network">
                {t("reports.network")}
              </option>
            )}
            {/* <option key="service" value="service">
              Service
            </option> */}
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-4">
          <Form.Label>
            {t("reports.groupBy")} <small>{t("reports.period")}</small>
          </Form.Label>
          <Form.Select
            name="groupByPeriod"
            value={values.groupByPeriod}
            onChange={(e) => {
              setGroupByPeriod(e.target.value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.groupByPeriod && errors.groupByPeriod)}
          >
            <option key="day" value="day">
              {t("reports.day")}
            </option>
            <option key="month" value="month">
              {t("reports.month")}
            </option>
            <option key="year" value="year">
              {t("reports.year")}
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          {/* <Form.Label>{t("reports.period")}</Form.Label> */}
          <div className="d-flex mt-4">
            <TextField
              fullWidth
              variant="outlined"
              value={
                values.usageStartDate && values.usageEndDate
                  ? format(values.usageStartDate, "dd/MM/yyyy") +
                    " - " +
                    format(values.usageEndDate, "dd/MM/yyyy")
                  : ""
              }
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              label={t("reports.period")}
              onClick={() => handleToggleUsageOpen()}
            />
            <IconButton
              onClick={() => handleToggleUsageOpen()}
              aria-label="toggle calendar"
              style={{ marginLeft: "8px" }}
            >
              <FaCalendarAlt />
            </IconButton>
            <DateRangePickerModal
              open={usageOpen}
              setOpen={setUsageOpen}
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.customer")}</Form.Label>
          <Form.Select
            name="customer"
            value={values.customer}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.customer && errors.customer)}
          >
            <option key="" value="">
              {t("reports.select")}...
            </option>
            <option key={user.id} value={user.id}>
              {user.role === "admin" && `${user.fullName} (Admin)`}
              {user.role !== "admin" &&
                user.role !== "subaccount" &&
                `${user.fullName} (${user.company})`}
            </option>
            {tenantsList?.map((tenant) => (
              <option key={tenant.id} value={tenant.id}>
                {tenant.fullName} ({tenant.company})
              </option>
            ))}
            {managersList?.map((manager) => (
              <option key={manager.id} value={manager.id}>
                {manager.fullName} ({manager.company})
              </option>
            ))}
            {customersList?.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.fullName} ({customer.company})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "usage"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.simSearchCriteria")}</Form.Label>
          <Form.Select
            name="simSearchCriteriaType"
            value={values.simSearchCriteriaType}
            onChange={(e) => {
              handleChange(e);
              setFieldValue("imsi", "");
              setFieldValue("startImsi", "");
              setFieldValue("endImsi", "");
              setFieldValue("iccid", "");
            }}
            onBlur={handleBlur}
            isInvalid={Boolean(
              touched.simSearchCriteriaType && errors.simSearchCriteriaType
            )}
          >
            <option key="1" value="all">
              {t("reports.all")}
            </option>
            <option key="2" value="imsi">
              {t("reports.bySpecificImsi")}
            </option>
            <option key="3" value="imsiRange">
              {t("reports.byImsiRange")}
            </option>
            <option key="4" value="iccid">
              {t("reports.byIccid")}
            </option>
          </Form.Select>
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsi" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.imsi")}</Form.Label>
          <Form.Control
            type="text"
            name="imsi"
            value={values.imsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.imsi && errors.imsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsiRange" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.startImsi")}</Form.Label>
          <Form.Control
            type="text"
            name="startImsi"
            value={values.startImsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.startImsi && errors.startImsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "imsiRange" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.endImsi")}</Form.Label>
          <Form.Control
            type="text"
            name="endImsi"
            value={values.endImsi}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.endImsi && errors.endImsi)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={nextCollapse === "iccid" && reportTypeCollapse === "usage"}
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>{t("reports.iccid")}</Form.Label>
          <Form.Control
            type="text"
            name="iccid"
            value={values.iccid}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.iccid && errors.iccid)}
          />
        </Form.Group>
      </Collapse>
      <Collapse
        in={
          reportTypeCollapse === "invoiceDetail" ||
          reportTypeCollapse === "invoiceOverview"
        }
        dimension="height"
      >
        <Form.Group className="mb-3">
          <Form.Label>Invoice Number</Form.Label>
          <Form.Control
            type="text"
            name="invoiceNumber"
            value={values.invoiceNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
          />
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "invoice"} dimension="height">
        <Form.Group className="mb-3">
          <Form.Label>Invoice Number</Form.Label>
          <Form.Control
            type="text"
            value={values.invoiceNumber}
            name="invoiceNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
          />
          {!!touched.invoiceNumber && (
            <Form.Control.Feedback type="invalid">
              {errors.invoiceNumber}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Collapse>
      <Collapse in={reportTypeCollapse === "orders"} dimension="height">
        <Form.Group className="mb-3">
          {/* <Form.Label>{t("reports.period")}</Form.Label> */}
          <div className="d-flex mt-4">
            <TextField
              fullWidth
              variant="outlined"
              value={
                values.orderStartDate && values.orderEndDate
                  ? format(values.orderStartDate, "dd/MM/yyyy") +
                    " - " +
                    format(values.orderEndDate, "dd/MM/yyyy")
                  : ""
              }
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              label={t("reports.period")}
              onClick={() => handleToggleOrderOpen()}
            />
            <IconButton
              onClick={() => handleToggleOrderOpen()}
              aria-label="toggle calendar"
              style={{ marginLeft: "8px" }}
            >
              <FaCalendarAlt />
            </IconButton>
            <DateRangePickerModal
              open={orderOpen}
              setOpen={setOrderOpen}
              values={values}
              setFieldValue={setFieldValue}
            />
          </div>
        </Form.Group>
      </Collapse>
      {isDesktop && (
        <Button
          type="submit"
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
        >
          {t("reports.view")}
        </Button>
      )}
      {!isDesktop && (
        <div className="d-flex justify-content-center mt-3">
          <Button
            type="submit"
            style={{
              "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
              "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
              border: "none",
              width: "100%",
              padding: "10px",
            }}
          >
            {t("reports.view")}
          </Button>
        </div>
      )}
    </Form>
  );
};

export default ReportsFilterForm;
