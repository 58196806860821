import React from "react";
import { Form } from "react-bootstrap";

const NewOneOffItem = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.name && errors.name)}
        />
        {!!touched.name && (
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.description && errors.description)}
        />
        {!!touched.description && (
          <Form.Control.Feedback type="invalid">
            {errors.description}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Price</Form.Label>
        <Form.Control
          type="text"
          name="price"
          value={values.price}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.price && errors.price)}
        />
        {!!touched.price && (
          <Form.Control.Feedback type="invalid">
            {errors.price}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>VAT(%)</Form.Label>
        <Form.Control
          type="text"
          value={values.vat}
          name="vat"
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.vat && errors.vat)}
        />
        {!!touched.vat && (
          <Form.Control.Feedback type="invalid">
            {errors.vat}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default NewOneOffItem;
