import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getOrder } from "../../../../redux/slices/orders";
import NotyfContext from "../../../../contexts/NotyfContext";
import { Modal, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../../components/Loader";

const OrderInformationModal = ({ showInfoModal, setShowInfoModal, order }) => {
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const isSimOrder =
    order.type === "ActivateSimOrder" ||
    order.type === "ResumeSimOrder" ||
    order.type === "SuspendSimOrder" ||
    order.type === "TerminateSimOrder" ||
    order.type === "UpdateLocationOrder";

  const hasOffer = !!order.offer;

  const action =
    order.type === "ActivateSimOrder"
      ? "Activate"
      : order.type === "SuspendSimOrder"
      ? "Suspend"
      : order.type === "ResumeSimOrder"
      ? "Resume"
      : order.type === "TerminateSimOrder"
      ? "Terminate"
      : order.type === "UpdateLocationOrder"
      ? "Update Location"
      : "Create SMS";

  function getCurrencySymbol(currencyCode) {
    const cc = require("currency-codes");
    const currency = cc.code(currencyCode);
    if (!currency) return null;

    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: currencyCode,
    })
      .formatToParts(1)
      .find((part) => part.type === "currency")?.value;
  }

  const handleClose = () => {
    setIsLoading(true); // Reset loading state
    setShowInfoModal(false);
  };

  useEffect(() => {
    const fetchOrderInformation = async () => {
      await dispatch(getOrder({ id: order.id }))
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        })
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
          setShowInfoModal(false);
        });
    };

    if (showInfoModal && order.id) {
      fetchOrderInformation();
    }
  }, [order.id, showInfoModal]);

  return (
    <Modal
      show={showInfoModal}
      onHide={handleClose}
      backdrop="static"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Order Information: {order.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {!isLoading && (
          <React.Fragment>
            <div className="mb-3">
              <div className="d-flex justify-content-center mb-3">
                <h2>General Information</h2>
              </div>
              <Table size="sm" className="my-2">
                <tbody>
                  <tr>
                    <th>{t("ID")}</th>
                    <td>{order.id}</td>
                  </tr>
                  <tr>
                    <th>{t("Name")}</th>
                    <td>{order.name}</td>
                  </tr>
                  <tr>
                    <th>{t("Creation Date")}</th>
                    <td>{order.creationDate}</td>
                  </tr>
                  <tr>
                    <th>{t("Execution Date")}</th>
                    <td>{order.lastUpdateDate}</td>
                  </tr>
                  <tr>
                    <th>{t("Created By")}</th>
                    <td>{order.created_by}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {isSimOrder && (
              <div className="mb-3">
                <div className="d-flex justify-content-center mb-3">
                  <h2>Sim Cards</h2>
                </div>
                <Table size="sm">
                  <thead>
                    <tr>
                      <th>IMSI</th>
                      <th>ICCID</th>
                      <th>MSISDN</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.sims.map((sim, index) => (
                      <tr key={index}>
                        <td>{sim.imsi}</td>
                        <td>{sim.iccid}</td>
                        <td>{sim.msisdn}</td>
                        <td>{action}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            {hasOffer && (
              <React.Fragment>
                <div className="mb-3">
                  <div className="d-flex justify-content-center mb-3">
                    <h2>Offer</h2>
                  </div>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Currency</th>
                        <th>Payment Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{order?.offer.name}</td>
                        <td>{order?.offer.currency}</td>
                        <td>{order?.offer.payment_type}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div className="mb-3">
                  <div className="d-flex justify-content-center mb-3">
                    <h2>Offer Items</h2>
                  </div>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Price</th>
                        <th>VAT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.offer.offerItems.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td>
                              {item?.price && item?.unitType
                                ? `${item.price}${getCurrencySymbol(
                                    order.offer.currency
                                  )}/${item.unitType}`
                                : `-`}
                            </td>
                            <td>{item?.vat ? `${item.vat}%` : `-`}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OrderInformationModal;
