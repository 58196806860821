import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../contexts/NotyfContext";
import useWindowSize from "../../hooks/useWindowSize";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import { getOfferOfferItems } from "../../redux/slices/offers";
import { Button, Modal, Offcanvas, Spinner, Table } from "react-bootstrap";
import Loader from "../../components/spinners/Loader";
import { FaPlus } from "react-icons/fa";
import NewOfferItemOffcanvas from "./NewOfferItemOffcanvas";

const OfferItemsModal = ({
  showManageOfferItemsModal,
  setShowManageOfferItemsModal,
  offerToManage,
}) => {
  const [isModalDisabled, setIsModalDisabled] = useState(false);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const { backgroundColor, fontColor } = useWhitelabel();
  const [internalShow, setInternalShow] = useState(showManageOfferItemsModal);
  const [fadeOut, setFadeOut] = useState(false);
  const user = useSelector((state) => state.user.user);
  const impersonationUser = useSelector(
    (state) => state.impersonationUser.impersonationUser
  );
  const [activeUser, setActiveUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const offerItems = useSelector((state) => state.offers.offerItems);
  const cc = require("currency-codes");
  const [showNewOfferItemOffcanvas, setShowNewOfferItemOffcanvas] =
    useState(false);

  function getCurrencySymbol(currencyCode) {
    const currency = cc.code(currencyCode);
    if (!currency) return null;

    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: currencyCode,
    })
      .formatToParts(1)
      .find((part) => part.type === "currency")?.value;
  }

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    if (showManageOfferItemsModal && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [showManageOfferItemsModal]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setShowManageOfferItemsModal(false);
    }, 300);
  };

  useEffect(() => {
    const fetchOfferItemsForOffer = async () => {
      setIsLoading(true);
      await dispatch(getOfferOfferItems({ id: offerToManage.id }))
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            ripple: true,
            dismissible: false,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        });
    };

    if (
      activeUser.id !== null &&
      showManageOfferItemsModal &&
      offerToManage.id !== null
    ) {
      fetchOfferItemsForOffer();
    }
  }, [activeUser, showManageOfferItemsModal, offerToManage]);

  if (isDesktop) {
    return (
      <Modal
        show={showManageOfferItemsModal}
        onHide={() => setShowManageOfferItemsModal(false)}
        centered
        backdrop="static"
        scrollable
        size="xl"
        // keyboard={!isModalDisabled}
      >
        <Modal.Header closeButton={!isModalDisabled}>
          <Modal.Title>Offer Items: {offerToManage.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            position: "relative",
          }}
        >
          {isModalDisabled && (
            <div
              style={{
                position: "absolute",
                inset: 0, // top:0, left:0, right:0, bottom:0
                backgroundColor: "rgba(255, 255, 255, 0.5)", // or any overlay color
                zIndex: 9999,
                // pointerEvents: "auto" ensures the overlay captures all clicks
                pointerEvents: "auto",
              }}
            />
          )}
          <div
            style={{
              opacity: isModalDisabled ? 0.6 : 1,
              pointerEvents: isModalDisabled ? "none" : "auto",
            }}
          >
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            )}
            {!isLoading && (
              <React.Fragment>
                <div className="d-flex justify-content-end mb-3">
                  <Button
                    variant="primary"
                    style={{
                      "--dynamic-bg-color": backgroundColor,
                      "--dynamic-font-color": fontColor,
                      border: "none",
                    }}
                    onClick={() => {
                      setIsModalDisabled(true);
                      setShowNewOfferItemOffcanvas(true);
                    }}
                  >
                    <FaPlus />
                    <span className="ms-2">New Offer Item</span>
                  </Button>
                </div>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Price</th>
                      <th>VAT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {offerItems.length > 0 && (
                      <>
                        {offerItems.map((offerItem, index) => {
                          return (
                            <tr key={index}>
                              <td>{offerItem.offerItem.name}</td>
                              <td>{offerItem.offerItem.type}</td>
                              {/* <td>{`${offerItem?.price}${getCurrencySymbol(
                            offerItem.offer?.currency
                          )}/${offerItem.offerItem?.unitType}`}</td> */}
                              <td>
                                {offerItem.offerItem.type ===
                                  "Subscription" && (
                                  <>{`${offerItem?.price}${getCurrencySymbol(
                                    offerItem.offer?.currency
                                  )}/${offerItem.offerItem?.unitType}`}</>
                                )}
                                {offerItem.offerItem.type === "Bucket" && (
                                  <>{`${offerItem.offerItem.amount}${
                                    offerItem.offerItem.unitType === "byte"
                                      ? offerItem.offerItem.unitType
                                          .charAt(0)
                                          .toUpperCase() +
                                        offerItem.offerItem.unitType
                                          .slice(1)
                                          .toLowerCase()
                                      : offerItem.offerItem.unitType.toUpperCase()
                                  }/${
                                    offerItem.offerItem.resetInterval ===
                                    "DAILY"
                                      ? "day"
                                      : offerItem.offerItem.resetInterval ===
                                        "WEEKLY"
                                      ? "week"
                                      : "month"
                                  }`}</>
                                )}
                                {offerItem.offerItem.type === "Discount" && (
                                  <>{`${
                                    offerItem.offerItem.percentage * 100.0
                                  }%`}</>
                                )}
                                {offerItem.offerItem.type === "Sim Product" && (
                                  <>
                                    {offerItem.price &&
                                    offerItem.offerItem.unitType
                                      ? `${offerItem?.price}${getCurrencySymbol(
                                          offerItem.offer?.currency
                                        )}/${offerItem.offerItem?.unitType}`
                                      : "-"}
                                  </>
                                )}
                                {offerItem.offerItem.type === "One-Off" && (
                                  <>
                                    {`${offerItem?.price}${getCurrencySymbol(
                                      offerItem.offer?.currency
                                    )}/${offerItem.offerItem?.unitType}`}
                                  </>
                                )}
                              </td>
                              <td>{`${offerItem.vat * 100}%`}</td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                    {offerItems.length === 0 && (
                      <tr>
                        <td colSpan={4}>No data available</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <NewOfferItemOffcanvas
                  showNewOfferItemOffcanvas={showNewOfferItemOffcanvas}
                  setShowNewOfferItemOffcanvas={setShowNewOfferItemOffcanvas}
                  setIsModalDisabled={setIsModalDisabled}
                  offerId={offerToManage.id}
                />
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setShowManageOfferItemsModal(false)}
            style={{
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
              border: "none",
            }}
            disabled={isModalDisabled}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <Offcanvas
      show={internalShow}
      placement="bottom"
      backdrop="static"
      scroll
      className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
      onHide={handleHide}
    >
      <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
        <Offcanvas.Title>Offer Items</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        className="fullscreen-offcanvas-body"
        style={
          isModalDisabled
            ? { pointerEvents: "none", opacity: 0.6 }
            : { pointerEvents: "auto" }
        }
      ></Offcanvas.Body>
    </Offcanvas>
  );
};

export default OfferItemsModal;
