import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";
import { format } from "date-fns";

import { AlertCircle, Bell, CheckCircle, DollarSign } from "react-feather";

import useSidebar from "../../hooks/useSidebar";

import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarLanguages from "./NavbarLanguages";
import NavbarUser from "./NavbarUser";
import useAuth from "../../hooks/useAuth";
import NavbarImpersonation from "./NavbarImpersonation";
import NotificationDropdown from "./NotificationDropdown";
import { useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";

const NavbarComponent = () => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = useSidebar();
  const { impersonationUser } = useAuth();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  return (
    <Navbar variant="light" expand className="navbar-bg">
      {isDesktop && (
        <span
          className="sidebar-toggle d-flex"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <i className="hamburger align-self-center" />
        </span>
      )}

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NotificationDropdown
            header="Notifications"
            footer="Show all notifications"
            icon={Bell}
          >
            {notifications.slice(0, 5).map((item, key) => {
              let icon = <AlertCircle size={18} className="text-warning" />;
              if (item.type === "INVOICE") {
                icon = <DollarSign size={18} className="text-primary" />;
              } else if (item.type === "WATCHDOG_WARNING") {
                icon = <AlertCircle size={18} className="text-danger" />;
              } else if (item.type === "ORDER") {
                icon = <CheckCircle size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.message}
                  time={item.createdAt}
                />
              );
            })}
          </NotificationDropdown>
          {/* <NavbarDropdown
            header="New Messages"
            footer="Show all messages"
            icon={MessageCircle}
            count={messages.length}
            showBadge
          >
            {messages.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={
                    <img
                      className="avatar img-fluid rounded-circle"
                      src={item.avatar}
                      alt={item.name}
                    />
                  }
                  title={item.name}
                  description={item.description}
                  time={item.time}
                  spacing
                />
              );
            })}
          </NavbarDropdown> */}

          {/* <NavbarDropdown
            header="New Notifications"
            footer="Show all notifications"
            icon={BellOff}
            count={notifications.length}
          >
            {notifications.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === "important") {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === "login") {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === "request") {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.title}
                  description={item.description}
                  time={item.time}
                />
              );
            })}
          </NavbarDropdown> */}

          <NavbarLanguages />
          {impersonationUser && <NavbarImpersonation />}

          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
