import {
  ButtonGroup,
  Card,
  Collapse,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import ReportsFilter from "./ReportsFilter.js";
import UsageTable from "./tables/UsageTable.js";
import { InvoiceTable, SimTable, IPAllocationTable } from "./ReportsTable.js";
import { useDispatch, useSelector } from "react-redux";
import NotyfContext from "../../../contexts/NotyfContext.js";
import Loader from "../../../components/spinners/Loader.js";
import { useTranslation } from "react-i18next";
import { useWhitelabel } from "../../../contexts/WhitelabelContext.js";
import OrdersTable from "./tables/OrdersTable.js";

const ReportsPage = () => {
  const [tableIsExpanded, setTableIsExpanded] = useState(false);
  const [tableIsShown, setTableIsShown] = useState(false);
  const [report, setReport] = useState("");
  const [usageType, setUsageType] = useState("");
  const [reportData, setReportData] = useState([]);
  const [groupByField, setGroupByField] = useState("");
  const [groupByPeriod, setGroupByPeriod] = useState("");
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { whitelabelLoading, faviconUrl } = useWhitelabel();

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [impersonationUser, user]);

  if (isLoading || whitelabelLoading) {
    return <Loader />;
  }
  return (
    <React.Fragment>
      <Helmet title="Reports">
        <link rel="icon" href={faviconUrl} />
      </Helmet>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("reports.title")}</h1>
        <Row>
          <Col md="12" lg="4" className="d-flex">
            <ReportsFilter
              tableIsExpanded={tableIsExpanded}
              tableIsShown={tableIsShown}
              setTableIsExpanded={setTableIsExpanded}
              setTableIsShown={setTableIsShown}
              report={report}
              setReport={setReport}
              usageType={usageType}
              setUsageType={setUsageType}
              groupByField={groupByField}
              setGroupByField={setGroupByField}
              reportData={reportData}
              setReportData={setReportData}
              setGroupByPeriod={setGroupByPeriod}
            />
          </Col>
          <Col md="12" lg="8" className="d-flex">
            <Collapse in={tableIsShown} dimension="height">
              <Card className="flex-fill w-100">
                <Card.Body>
                  {report === "usage" ? (
                    <UsageTable
                      usageType={usageType}
                      groupByField={groupByField}
                      data={reportData}
                      groupByPeriod={groupByPeriod}
                    />
                  ) : report === "invoice" ? (
                    <InvoiceTable />
                  ) : report === "sim_list" ? (
                    <SimTable />
                  ) : report === "ip_allocation" ? (
                    <IPAllocationTable />
                  ) : report === "orders" ? (
                    <OrdersTable />
                  ) : null}
                </Card.Body>
              </Card>
            </Collapse>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ReportsPage;
