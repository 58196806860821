import React, { useEffect, useState } from "react";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import useWindowSize from "../../../hooks/useWindowSize";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { DateRangePicker, DateRange } from "react-date-range";
import { useWhitelabel } from "../../../contexts/WhitelabelContext";
import { format } from "date-fns";

const DateRangePickerModal = ({ open, setOpen, values, setFieldValue }) => {
  const [width] = useWindowSize();
  const isDesktop = width > 768;
  const [internalShow, setInternalShow] = useState(open);
  const [fadeOut, setFadeOut] = useState(false);
  const [state, setState] = useState([
    {
      startDate: values?.usageStartDate
        ? new Date(values.usageStartDate)
        : new Date(),
      endDate: values?.usageEndDate
        ? new Date(values.usageEndDate)
        : new Date(),
      key: "selection",
    },
  ]);
  const {
    whitelabelLoading,
    whitelabelUrl,
    faviconUrl,
    backgroundColor,
    fontColor,
  } = useWhitelabel();

  useEffect(() => {
    if (open && !isDesktop) {
      setFadeOut(false);
      setInternalShow(true);
    }
  }, [open]);

  const handleHide = () => {
    setFadeOut(true);
    setTimeout(() => {
      setInternalShow(false);
      setOpen(false);
    }, 300);
  };

  if (isDesktop) {
    return (
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        scrollable
        backdrop="static"
        centered
      >
        <Modal.Body>
          <DateRangePicker
            onChange={(item) => {
              const { startDate, endDate } = item.selection;
              setState([item.selection]);
              if (values.reportType === "usage") {
                setFieldValue("usageStartDate", startDate);
                setFieldValue("usageEndDate", endDate);
              } else if (values.reportType === "orders") {
                setFieldValue("orderStartDate", startDate);
                setFieldValue("orderEndDate", endDate);
              }
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
            direction="horizontal"
            calendarFocus="backwards"
            maxDate={new Date()}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setOpen(false)}
            style={{
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
              border: "none",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Offcanvas
        show={open}
        onHide={handleHide}
        placement="bottom"
        backdrop="static"
        scrollable="true"
        className={`fullscreen-offcanvas ${fadeOut ? "fade-out" : ""}`}
      >
        <Offcanvas.Header closeButton className="fullscreen-offcanvas-header">
          <Offcanvas.Title>Select Date Range</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="fullscreen-offcanvas-body">
          <div className="d-flex justify-content-center align-items-center">
            <DateRange
              onChange={(item) => {
                const { startDate, endDate } = item.selection;
                if (values.reportType === "usage") {
                  setFieldValue("usageStartDate", startDate);
                  setFieldValue("usageEndDate", endDate);
                } else if (values.reportType === "orders") {
                  setFieldValue("orderStartDate", startDate);
                  setFieldValue("orderEndDate", endDate);
                }

                setState([item.selection]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
              rangeColors={[backgroundColor]}
              weekStartsOn={1}
              className="fullwidth-daterange"
              maxDate={new Date()}
            />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Button
              variant="primary"
              onClick={() => setOpen(false)}
              style={{
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
                border: "none",
                width: "100%",
                padding: "10px",
              }}
            >
              Close
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
};

export default DateRangePickerModal;
