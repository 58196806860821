import React, { createContext, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

const WhitelabelContext = createContext();

export const WhitelabelProvider = ({ children }) => {
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [whitelabelLoading, setWhitelabelLoading] = useState(true);
  const [error, setError] = useState(null);
  const [whitelabelUrl, setWhitelabelUrl] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [faviconUrl, setFaviconUrl] = useState(null);

  useEffect(() => {
    const loadWhitelabelData = async () => {
      try {
        if (
          whitelabel &&
          whitelabel?.whitelabelImage &&
          whitelabel?.whitelabelImage?.imagePath
        ) {
          setWhitelabelUrl(
            `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel.whitelabelImage.imagePath}`
          );
        } else {
          setWhitelabelUrl(null);
        }
        if (
          whitelabel &&
          whitelabel?.tabImage &&
          whitelabel?.tabImage?.imagePath
        ) {
          setFaviconUrl(
            `${process.env.REACT_APP_POS2CLOUD_IMAGE_URL}${whitelabel.tabImage.imagePath}`
          );
        } else {
          setFaviconUrl(null);
        }
        if (whitelabel && whitelabel?.backgroundColor) {
          setBackgroundColor(whitelabel?.backgroundColor);
        } else {
          setBackgroundColor("#00acdc");
        }
        if (whitelabel && whitelabel?.fontColor) {
          setFontColor(whitelabel?.fontColor);
        } else {
          setFontColor("#ffffff");
        }
        // if (
        //   whitelabel &&
        //   whitelabel?.whitelabelImage &&
        //   whitelabel?.whitelabelImage?.imagePath &&
        //   whitelabel?.tabImage &&
        //   whitelabel?.tabImage?.imagePath &&
        //   whitelabel.backgroundColor &&
        //   whitelabel.fontColor
        // ) {

        //   setBackgroundColor(whitelabel?.backgroundColor ?? "#00acdc");

        // } else {
        //   setWhitelabelUrl(null);
        //   setFaviconUrl(null);
        // }
      } catch (err) {
        setError("Failed to load whitelabel data.");
      } finally {
        setTimeout(() => {
          setWhitelabelLoading(false);
        }, 1200);
      }
    };

    loadWhitelabelData();
  }, [whitelabel]);

  return (
    <WhitelabelContext.Provider
      value={{
        whitelabelLoading,
        error,
        whitelabelUrl,
        faviconUrl,
        backgroundColor,
        fontColor,
      }}
    >
      {children}
    </WhitelabelContext.Provider>
  );
};

export const useWhitelabel = () => {
  const context = useContext(WhitelabelContext);
  if (!context) {
    throw new Error("useWhitelabel must be used within a WhitelabelProvider");
  }
  return context;
};
