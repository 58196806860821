import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import soundFile from "../assets/sounds/message-notification.wav";
import Pusher from "pusher-js";
import { updateSimsStatus } from "../redux/slices/sims";

const SimContext = React.createContext();

export const SimProvider = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const impersonationToken = window.localStorage.getItem("impersonationToken");
  const accessToken = window.localStorage.getItem("accessToken");
  const [pusherInstance, setPusherInstance] = useState(null); // Keep track of pusher instance
  const [channelInstance, setChannelInstance] = useState(null); // Keep track of current channel
  const dispatch = useDispatch();
  const audio = new Audio(soundFile);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const subscribeToPusher = () => {
      if (channelInstance) {
        channelInstance.unbind_all(); // Unbind all events from the channel
        channelInstance.unsubscribe(); // Unsubscribe from the old channel
      }
      if (pusherInstance) {
        pusherInstance.disconnect(); // Disconnect old Pusher connection
      }
      const newPusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        authEndpoint: `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/pusher/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        },
      });

      const newChannel = newPusher.subscribe(`private-user-${activeUser.id}`);

      // Listen for events on the channel
      newChannel.bind("status", (data) => {
        const parsedData = data; // Ensure the payload is an object
        dispatch(updateSimsStatus(parsedData));
      });

      setPusherInstance(newPusher);
      setChannelInstance(newChannel);
    };

    if (activeUser.id) {
      subscribeToPusher();
    }

    return () => {
      if (channelInstance) {
        channelInstance.unbind_all();
        channelInstance.unsubscribe();
      }
      if (pusherInstance) {
        pusherInstance.disconnect();
      }
    };
  }, [activeUser.id]);

  return (
    <SimContext.Provider
      value={{ activeUser, pusherInstance, channelInstance }}
    >
      {children}
    </SimContext.Provider>
  );
};
