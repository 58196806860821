import React, { useContext, useState } from "react";
import { Button, Collapse, Form, Offcanvas } from "react-bootstrap";
import { useWhitelabel } from "../../contexts/WhitelabelContext";
import { Formik } from "formik";
import * as Yup from "yup";
import NewSubscriptionForm from "./NewSubscriptionForm";
import NewBucketForm from "./NewBucketForm";
import NewSimForm from "./NewSimForm";
import { useDispatch } from "react-redux";
import { storeOfferItem } from "../../redux/slices/offers";
import NotyfContext from "../../contexts/NotyfContext";
import NewDiscount from "./NewDiscount";
import NewWatchdogForm from "./NewWatchdogForm";
import NewOneOffItem from "./NewOneOffItem";

const NewOfferItemOffcanvas = ({
  showNewOfferItemOffcanvas,
  setShowNewOfferItemOffcanvas,
  setIsModalDisabled,
  offerId,
}) => {
  const dispatch = useDispatch();
  const { backgroundColor, fontColor } = useWhitelabel();
  const notyf = useContext(NotyfContext);
  const [openSection, setOpenSection] = useState("");
  const initialValues = {
    type: "",
    name: "",
    description: "",
    price: "",
    vat: "",
    sharingType: "",
    changeable: false,
    enableWarning: false,
    warning: "",
    amount: "",
    enableResetInterval: false,
    resetInterval: "",
    unitType: "",
    simType: "",
    percentage: "",
    bindingPeriod: {
      amount: "",
      period: "",
    },
    actionType: "",
    amount: "",
    warning: "",
    warning1: "",
    warning2: "",
    service: "",
    enableSuspendInterval: false,
    suspendInterval: {
      amount: "",
      period: "",
    },
    watchdogInterval: {
      amount: "",
      period: "",
    },
  };

  const validation = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().notRequired(),
    price: Yup.number().when("type", {
      is: (type) => type === "Subscription" || type === "Sim",
      then: Yup.number("Price must be a number")
        .min(0, "Price must be a positive number")
        .required("Price is required"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    vat: Yup.number().when("type", {
      is: (type) => type === "Subscription" || type === "Sim",
      then: Yup.number("VAT must a number")
        .min(0, "VAT must be a positive number")
        .max(100, "VAT must be a percentage")
        .required("VAT is required"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    sharingType: Yup.string().when("type", {
      is: "Bucket",
      then: Yup.string().required("Sharing tpye is local"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    changeable: Yup.boolean().when("type", {
      is: "Bucket",
      then: Yup.boolean().required("Required"),
      otherwise: Yup.boolean().nullable().notRequired(),
    }),
    enableWarning: Yup.boolean().notRequired(),
    warning: Yup.number().when("enableWarning", {
      is: true,
      then: Yup.number().min(0).required("Required"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    amount: Yup.number().when("type", {
      is: "Bucket",
      then: Yup.number("Amount must be a number")
        .min(0, "Must be a positive number")
        .required("Amount is required"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    unitType: Yup.string().when("type", {
      is: (unitType) => unitType !== "Discount" && unitType !== "One-Off",
      then: Yup.string().required("Unit Type is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    enableResetInterval: Yup.boolean(),
    resetInterval: Yup.string().when("type", {
      is: "Bucket",
      then: Yup.string().required("Reset Interval is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    simType: Yup.string().when("type", {
      is: "Sim",
      then: Yup.string().required("Sim Type is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    percentage: Yup.number().when("type", {
      is: "Discount",
      then: Yup.number()
        .min(0, "Percentage must be a positive number")
        .max(100, "Maximum value is 100")
        .required("Percentage is required"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    bindingPeriod: Yup.object().shape({
      amount: Yup.number().when("type", {
        is: "Discount",
        then: Yup.number()
          .min(0, "Must be a positive number")
          .required("Required"),
        otherwise: Yup.number().nullable().notRequired(),
      }),
      period: Yup.string().when("type", {
        is: "Discount",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
    actionType: Yup.string().when("type", {
      is: "Watchdog",
      then: Yup.string().required("Action Type is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    amount: Yup.number().when("type", {
      is: "Watchdog",
      then: Yup.number("Must be a number")
        .min(0, "Must be positive")
        .required("Amount is required"),
      otherwise: Yup.number().nullable().notRequired(),
    }),
    warning: Yup.number("Must be a number").nullable().notRequired(),
    warning1: Yup.number("Must be a number").nullable().notRequired(),
    warning2: Yup.number("Must be a number").nullable().notRequired(),
    service: Yup.string().when("type", {
      is: "Watchdog",
      then: Yup.string().required("Required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    enableSuspendInterval: Yup.boolean(),
    suspendInterval: Yup.object().shape({
      amount: Yup.number().when("enableSuspendInterval", {
        is: true,
        then: Yup.number("Must be a number")
          .min(0, "Must be a positive number")
          .required("Required"),
        otherwise: Yup.number().nullable().notRequired(),
      }),
      period: Yup.string().when("enableSuspendInterval", {
        is: true,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
    watchdogInterval: Yup.object().shape({
      amount: Yup.number().when("type", {
        is: "Watchdog",
        then: Yup.number("Must be a number")
          .min(0, "Must be a positive number")
          .required("Required"),
        otherwise: Yup.number().nullable().notRequired(),
      }),
      period: Yup.string().when("type", {
        is: "Watchdog",
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable().notRequired(),
      }),
    }),
  });

  return (
    <Offcanvas
      placement="end"
      show={showNewOfferItemOffcanvas}
      onHide={() => {
        setShowNewOfferItemOffcanvas(false);
        setIsModalDisabled(false);
      }}
      style={{ zIndex: 1060, boxShadow: "0 4px 8px rgba(0, 0, 0, 0.45)" }}
      // backdrop="true"
      scrolable="true"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>New Offer Item</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          onSubmit={async (values, { setValues }) => {
            const data = {
              type: values.type,
              offerId: offerId,
            };
            if (values.type === "Subscription") {
              data.name = values.name;
              data.description = values.description;
              data.price = values.price;
              data.vat = values.vat / 100.0;
              data.unitType = values.unitType;
            } else if (values.type === "Bucket") {
              data.name = values.name;
              data.description = values.description;
              data.sharingType = values.sharingType;
              data.changeable = values.changeable;
              if (values.enableWarning) {
                data.warning = values.warning;
              }
              data.amount = values.amount;
              data.unitType = values.unitType;
              data.resetInterval = values.resetInterval;
            } else if (values.type === "Sim") {
              data.simType = values.simType;
              data.name = values.simType;
              data.price = values.price;
              data.vat = values.vat / 100.0;
              data.unitType = values.unitType;
            } else if (values.type === "Discount") {
              data.name = values.name;
              data.description = values.description;
              data.percentage = values.percentage / 100.0;
              if (values.bindingPeriod.period === "day") {
                data.bindingPeriod = values.bindingPeriod.amount;
              } else {
                data.bindingPeriod = values.bindingPeriod * 30;
              }
            } else if (values.type === "Watchdog") {
              data.name = values.name;
              data.description = values.description;
              data.actionType = values.actionType;
              data.amount = values.amount;
              data.warning = values.warning;
              data.warning1 = values.warning1;
              data.warning2 = values.warning2;
              data.service = values.service;
              data.unitType = values.unitType;
              if (
                values.actionType === "SUSPEND" &&
                values.enableSuspendInterval === true
              ) {
                if (values.suspendInterval.period === "months") {
                  data.suspendInterval = values.suspendInterval.amount * 30;
                } else {
                  data.suspendInterval = values.suspendInterval.amount;
                }
              }
              if (values.watchdogInterval.period === "months") {
                data.watchdogInterval = values.watchdogInterval.amount * 30;
              } else {
                data.watchdogInterval = values.watchdogInterval.amount;
              }
            } else if (values.type === "One-Off") {
              data.name = values.name;
              data.description = values.description;
              data.price = values.price;
              data.vat = values.vat / 100.0;
              data.unitType = "unit";
            }
            await dispatch(storeOfferItem({ data }))
              .then(() => {
                notyf.open({
                  type: "success",
                  message: "Offer Item stored successfully",
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .catch((error) => {
                notyf.open({
                  type: "danger",
                  message: error,
                  ripple: true,
                  dismissible: false,
                  position: {
                    x: "center",
                    y: "top",
                  },
                });
              })
              .finally(() => {
                setValues(initialValues);
                setShowNewOfferItemOffcanvas(false);
                setIsModalDisabled(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    name="type"
                    value={values.type}
                    onChange={(e) => {
                      setOpenSection("");
                      setFieldValue("type", e.target.value);
                      setTimeout(() => {
                        setOpenSection(e.target.value);
                      }, 350);
                    }}
                    onBlur={handleBlur}
                    isInvalid={Boolean(touched.type && errors.type)}
                  >
                    <option value="">Select Type</option>
                    <option value="Bucket">Bucket</option>
                    <option value="Subscription">Subscription</option>
                    <option value="Sim">SIM Card</option>
                    <option value="Discount">Discount</option>
                    <option value="Watchdog">Watchdog</option>
                    <option value="One-Off">One-Off</option>
                  </Form.Select>
                  {!!touched.type && (
                    <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Collapse
                  in={openSection === "Subscription"}
                  dimension="height"
                >
                  <div>
                    <NewSubscriptionForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </Collapse>
                <Collapse in={openSection === "Bucket"} dimension="height">
                  <div>
                    <NewBucketForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </Collapse>
                <Collapse in={openSection === "Sim"} dimension="height">
                  <div>
                    <NewSimForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </Collapse>
                <Collapse in={openSection === "Discount"} dimension="height">
                  <div>
                    <NewDiscount
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </Collapse>
                <Collapse in={openSection === "Watchdog"} dimension="height">
                  <div>
                    <NewWatchdogForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </Collapse>
                <Collapse in={openSection === "One-Off"} dimension="height">
                  <div>
                    <NewOneOffItem
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </Collapse>
                <div className="my-3 d-flex flex-column justify-content-center">
                  <Button
                    variant="primary"
                    className="mb-3"
                    style={{
                      "--dynamic-bg-color": backgroundColor,
                      "--dynamic-font-color": fontColor,
                      border: "none",
                      width: "100%",
                      padding: "10px",
                    }}
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    variant="primary"
                    style={{
                      "--dynamic-bg-color": backgroundColor,
                      "--dynamic-font-color": fontColor,
                      border: "none",
                      width: "100%",
                      padding: "10px",
                    }}
                    onClick={() => {
                      setShowNewOfferItemOffcanvas(false);
                      setIsModalDisabled(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NewOfferItemOffcanvas;
