import { original } from "@reduxjs/toolkit";
import { FaCheck } from "react-icons/fa";

const getInvoiceData = (t) => {
  const invoicesColumns = [
    {
      Header: t("invoices.invoiceNo"),
      accessor: "id",
      sortable: true,
    },
    {
      Header: t("invoices.date"),
      accessor: "creationDate",
      sortable: true,
      Cell: ({ row: { original } }) => (
        <>{original.creationDate.replace("T", " ")}</>
      ),
    },
    {
      Header: t("invoices.amount"),
      accessor: "amount",
      Cell: ({ row: { original } }) => <>{original.amount}€</>,
    },
    {
      Header: t("invoices.description"),
      accessor: "description",
      sortable: true,
    },
    {
      Header: t("invoices.paid"),
      accessor: "isPaid",
      sortable: true,
      Cell: ({ row: { original } }) => (
        <>{original.isPaid === "no" ? "" : <FaCheck />}</>
      ),
    },
  ];

  return { invoicesColumns };
};

export default getInvoiceData;
