import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";

import { AuthProvider } from "./contexts/JWTContext";
import { fetchWhitelabel } from "./redux/slices/whitelabel";
import { NotificationProvider } from "./contexts/NotificationContext";
import { StompSessionProvider } from "react-stomp-hooks";
import { SimFiltersProvider } from "./contexts/SimFiltersContext";
import { WhitelabelProvider } from "./contexts/WhitelabelContext";
import { SimProvider } from "./contexts/SimContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | ClickSims - Connectivity Made Easy" />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              <SimProvider>
                <NotificationProvider>
                  <SimFiltersProvider>
                    <ChartJsDefaults />
                    <AuthProvider>
                      <WhitelabelProvider>{content}</WhitelabelProvider>
                    </AuthProvider>
                  </SimFiltersProvider>
                </NotificationProvider>
              </SimProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
