import { dispatch } from "d3";
import axios from "../../utils/axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sims: [],
  count: 0,
  inventory: [],
  domainSims: [],
  sim: {},
  cellInfo: {},
  network: {},
  hlrInfo: {},
  simsByStatus: [],
  simsByOffer: [],
  simsByNetwork: [],
  sim_data_session_count: 0,
  sim_data_sessions_difference_percentage: 0,
  sim_data_volume: 0,
  sim_data_volume_difference_percentage: 0,
  sim_zero_session_count: 0,
  sim_zero_session_difference_percentage: 0,
  sim_alerts_count: 0,
  sim_alerts_difference_percentage: 0,
  by_month: [],
  sim_sessions: [],
};

const simsSlice = createSlice({
  name: "sims",
  initialState,
  reducers: {
    setSims(state, action) {
      state.sims = action.payload;
    },
    setCount(state, action) {
      state.count = action.payload;
    },
    setInventory(state, action) {
      state.inventory = action.payload;
    },
    setDomainSims(state, action) {
      state.domainSims = action.payload;
    },
    addSims(state, action) {
      const newSims = action.payload;
      return {
        ...state,
        sims: {
          ...state.sims,
          sims: [...state.sims.sims, ...newSims],
        },
      };
    },
    removeDomainSims(state, action) {
      const simsToRemove = action.payload;
      const newDomainSims = state.domainSims.filter(
        (sim) => !simsToRemove.includes(sim.id)
      );
      return {
        ...state,
        domainSims: newDomainSims,
      };
    },
    updateSim(state, action) {
      const updatedSims = action.payload; // Assuming this is an array of updated SIMs

      // Create a map of updated SIMs by IMSI for quick lookup
      const updatedSimMap = new Map();
      updatedSims.forEach((sim) => updatedSimMap.set(sim.imsi, sim));

      // Update the state.sims array
      const newSims = state.sims.sims.map((sim) => {
        if (updatedSimMap.has(sim.imsi)) {
          return updatedSimMap.get(sim.imsi);
        }
        return sim;
      });

      return {
        ...state,
        sims: {
          ...state.sims,
          sims: newSims,
        },
      };
    },
    updateSimsStatus(state, action) {
      const updatedSims = action.payload.sims; // List of SIMs from WebSocket payload

      updatedSims.forEach((updatedSim) => {
        const existingSim = state.sims.sims.find(
          (sim) => sim.imsi === updatedSim.imsi
        );

        if (existingSim) {
          existingSim.status = updatedSim.status;
          existingSim.state = updatedSim.state;
        }
      });
    },

    setSim(state, action) {
      state.sim = action.payload;
    },
    setCellInfo(state, action) {
      state.cellInfo = action.payload;
    },
    setNetwork(state, action) {
      state.network = action.payload;
    },
    setHlrInfo(state, action) {
      state.hlrInfo = action.payload;
    },
    setSimsByStatus(state, action) {
      state.simsByStatus = action.payload;
    },
    setSimsByOffer(state, action) {
      state.simsByOffer = action.payload;
    },
    setSimsByNetwork(state, action) {
      state.simsByNetwork = action.payload;
    },
    setSimDataSessionCount(state, action) {
      state.sim_data_session_count = action.payload;
    },
    setSimDataSessionDifferencePercentage(state, action) {
      state.sim_data_sessions_difference_percentage = action.payload;
    },
    setSimDataVolume(state, action) {
      state.sim_data_volume = action.payload;
    },
    setSimDataVolumeDifferencePercentage(state, action) {
      state.sim_data_volume_difference_percentage = action.payload;
    },
    setSimZeroSessionCount(state, action) {
      state.sim_zero_session_count = action.payload;
    },
    setSimZeroSessionDifferencePercentage(state, action) {
      state.sim_zero_session_difference_percentage = action.payload;
    },
    setSimAlertsCount(state, action) {
      state.sim_alerts_count = action.payload;
    },
    setSimAlertsDifferencePercentage(state, action) {
      state.sim_alerts_difference_percentage = action.payload;
    },
    setByMonth(state, action) {
      state.by_month = action.payload;
    },
    setSimSessions(state, action) {
      state.sim_sessions = action.payload;
    },
  },
});

export function getSims({
  page,
  pageSize,
  filters,
  sortField = "imsi",
  sortOrder = "asc",
}) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const filterParams = Object.entries(filters)
        .reduce((params, [key, value]) => {
          if (value) {
            params.push(`${key}=${encodeURIComponent(value)}`);
          }
          return params;
        }, [])
        .join("&");
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim?page=${page}&pageSize=${pageSize}&${filterParams}&sortField=${sortField}&sortDirection=${sortOrder}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setSims(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSim(imsi) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim?imsi=${imsi}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setSim(response.data.data));
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimsByStatus({ page, pageSize, status, query }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/bystatus?page=${page}&pageSize=${pageSize}&status=${status}&query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setSimsByStatus(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimsByOffer({ page, pageSize, offer, query }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/byoffer?page=${page}&pageSize=${pageSize}&offer=${offer}&query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setSimsByOffer(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimsByNetwork({ page, pageSize, network, country, query }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/bynetwork?page=${page}&pageSize=${pageSize}&network=${network}&country=${country}&query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setSimsByNetwork(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimInventory(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/${id}/inventory`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );
      dispatch(simsSlice.actions.setInventory(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      dispatch(simsSlice.actions.setInventory([]));
      return Promise.reject("Error");
    }
  };
}

export function getSimInventoryCount(filters) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    const filterParams = Object.entries(filters)
      .reduce((params, [key, value]) => {
        if (value) {
          params.push(`${key}=${encodeURIComponent(value)}`);
        }
        return params;
      }, [])
      .join("&");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/inventory/count?&${filterParams}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setCount(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      dispatch(simsSlice.actions.setCount(-1));
      return Promise.reject(error.message);
    }
  };
}

export function getSimCount({ status, offer, query }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      let params = "";
      if (status) {
        params += `status=${status}&`;
      } else {
        params += `status=&`;
      }
      if (offer) {
        params += `offer=${offer}&`;
      } else {
        params += `offer=&`;
      }
      if (query) {
        params += `&query=${query}`;
      } else {
        params += `query=`;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/count?${params}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setCount(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimCellInfo(imsi) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/cellInfo?imsi=${imsi}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setCellInfo(response.data.data.cellInfo));
      dispatch(simsSlice.actions.setNetwork(response.data.data.network));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function activateSims({ sims, offer }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        imsiList: sims,
        offer: String(offer),
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/activate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      // dispatch(simsSlice.actions.updateSim(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function suspendSims(sims) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        imsiList: sims,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/suspend`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      // dispatch(simsSlice.actions.updateSim(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function terminateSims({ sims, targetState }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        imsiList: sims,
        targetState: targetState,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/terminate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      // dispatch(simsSlice.actions.updateSim(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function resumeSims(sims) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        imsiList: sims,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/resume`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      // dispatch(simsSlice.actions.updateSim(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function modifySimProduct({ sim, targetOffer }) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        imsi: sim,
        targetOffer: targetOffer,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/modify`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function initializeSims(id) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        id: id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/init`,
        id,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function getTestProfiles() {}

export async function toggleTestMode(
  sims,
  action,
  testProfile,
  dataAllowance,
  smsAllowance,
  number,
  interval,
  offer,
  id
) {
  const accessToken = window.localStorage.getItem("accessToken");
  try {
    const data = {
      imsiList: sims,
      action: action,
      testProfile: testProfile,
      dataAllowance: dataAllowance,
      smsAllowance: smsAllowance,
      number: number,
      interval: interval,
      offer: offer,
    };
    const response = await axios.put(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/${id}/toggleTestMode`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Toggle Test Mode Failed");
  }
}

export function getDomainSims(page, pageSize, search) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/getDomainSims?page=${page}&pageSize=${pageSize}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch(simsSlice.actions.setDomainSims(response.data));
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function shipSims({ id, sims, price, vat }) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) return Promise.reject("No access token");
      const data = {
        id: id,
        imsiList: sims.map((sim) => sim.imsi),
        price: price,
        vat: vat,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/ship`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve("Success");
    } catch (error) {
      return Promise.reject("Error");
    }
  };
}

export function deallocateSims({ sims }) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) return Promise.reject("No access token");
      const data = {
        imsiList: sims.map((sim) => sim.imsi),
      };

      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/deallocate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve("Sims deallocated successfully");
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function addWatchdog(
  id,
  sims,
  actionType,
  amount,
  notificationType,
  service,
  suspendInterval,
  unitType,
  warning,
  warning1,
  warning2,
  watchDogInterval,
  watchDogType,
  distributionList
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: sims,
    actionType: actionType,
    amount: amount,
    emailList: distributionList,
    notificationType: notificationType,
    service: service,
    suspendInterval: suspendInterval,
    unitType: unitType,
    warning: warning,
    warning1: warning1,
    warning2: warning2,
    watchDogInterval: watchDogInterval,
    watchDogType: watchDogType,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog/${id}/addWatchdog`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export async function removeAllSimWatchdogs(id, sims) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: sims,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/watchdog/${id}/removeWatchdog`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export async function sendSms(
  id,
  sims,
  fromType,
  from,
  messageType,
  textContent,
  base64Content
) {
  const accessToken = window.localStorage.getItem("accessToken");
  const data = {
    imsiList: sims,
    fromType: fromType,
    from: from,
    messageType: messageType,
    textContent: textContent,
    base64Content: base64Content,
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sms/${id}/sendSms`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export async function retrieveSms(id, imsi) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sms/${id}/retrieve?imsi=${imsi}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export function updateSimLocation(imsiList) {
  return async (dispatch) => {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const data = {
        imsiList: imsiList,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/updateLocation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function imeiLock(id, imsi, imei) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const data = {
      imsi: imsi,
      imei: imei,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/${id}/imeiLock`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject("Error");
  }
}

export function getHlrInfo({ imsi, scope }) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const impersonationToken =
        window.localStorage.getItem("impersonationToken");
      if (!accessToken) return Promise.reject("No access token");
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/hlr?imsi=${imsi}&scope=${scope}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setHlrInfo(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function exportSims({ imsiList, format }) {
  const accessToken = window.localStorage.getItem("accessToken");
  const impersonationToken = window.localStorage.getItem("impersonationToken");
  if (!accessToken) return Promise.reject("No access token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/export`,
      {
        imsiList: imsiList,
        format: format,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${
            impersonationToken ? impersonationToken : accessToken
          }`,
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error.message);
  }
}

export function getSimStatistics({ id, period }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/${id}/counts?period=${period}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        simsSlice.actions.setSimDataSessionCount(
          response.data.data.currentPeriodDataSessions
        )
      );
      dispatch(
        simsSlice.actions.setSimDataSessionDifferencePercentage(
          response.data.data.data_sessions_difference_percentage
        )
      );
      dispatch(
        simsSlice.actions.setSimDataVolume(
          response.data.data.currentPeriodDataVolume
        )
      );
      dispatch(
        simsSlice.actions.setSimDataVolumeDifferencePercentage(
          response.data.data.data_volume_difference_percentage
        )
      );
      dispatch(
        simsSlice.actions.setSimZeroSessionCount(
          response.data.data.currentPeriodZeroSessions
        )
      );
      dispatch(
        simsSlice.actions.setSimZeroSessionDifferencePercentage(
          response.data.data.zero_session_difference_percentage
        )
      );
      dispatch(
        simsSlice.actions.setSimAlertsCount(
          response.data.data.currentPeriodAlerts
        )
      );
      dispatch(
        simsSlice.actions.setSimAlertsDifferencePercentage(
          response.data.data.alerts_difference_percentage
        )
      );
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getDistributionForSim({ id, type }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/${id}/bymonth?type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(simsSlice.actions.setByMonth(response.data.data));
      return Promise.resolve(response.data.message);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export function getSimDataSessions({ page, pageSize, id }) {
  return async (dispatch) => {
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) return Promise.reject("No access token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/${id}/sessions?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${
              impersonationToken ? impersonationToken : accessToken
            }`,
          },
        }
      );
      dispatch(
        simsSlice.actions.setSimSessions(response.data.data.dataSessions)
      );
      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(error.message);
    }
  };
}

export async function findSimsByFile({ file, id = null }) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    const formData = new FormData();
    formData.append("file", file);
    if (id) {
      formData.append("id", id);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/byfile`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${
            impersonationToken ? impersonationToken : accessToken
          }`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return Promise.resolve(response.data.data);
  } catch (error) {
    return Promise.reject(error.message);
  }
}

export async function findSimsByRange({
  minImsi = null,
  maxImsi = null,
  minIccid = null,
  maxIccid = null,
  id = null,
}) {
  try {
    const accessToken = window.localStorage.getItem("accessToken");
    const impersonationToken =
      window.localStorage.getItem("impersonationToken");
    if (!accessToken) return Promise.reject("No access token");
    const response = await axios.get(
      `${process.env.REACT_APP_POS2CLOUD_BACKEND_URL}/sim/byrange`,
      {
        headers: {
          Authorization: `Bearer ${
            impersonationToken ? impersonationToken : accessToken
          }`,
        },
        params: {
          minImsi: minImsi,
          maxImsi: maxImsi,
          minIccid: minIccid,
          maxIccid: maxIccid,
          id: id,
        },
      }
    );
    return Promise.resolve(response.data.data);
  } catch (error) {
    return Promise.reject(error.message);
  }
}

export const {
  setSims,
  setCount,
  setInventory,
  addSims,
  removeDomainSims,
  updateSimsStatus,
} = simsSlice.actions;

export default simsSlice.reducer;
