import React, { useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import OrderInformationModal from "./modals/OrderInformationModal";

const Order = ({ order }) => {
  const { t } = useTranslation();
  const [showInfoModal, setShowInfoModal] = useState(false);

  return (
    <Card className="mb-3 bg-light cursor-grab border">
      <Card.Body className="p-3">
        <Table size="sm" className="my-2">
          <tbody>
            <tr>
              <th>{t("ID")}</th>
              <td>{order.id}</td>
            </tr>
            <tr>
              <th>{t("Name")}</th>
              <td>{order.name}</td>
            </tr>
            <tr>
              <th>{t("Creation Date")}</th>
              <td>{order.creationDate}</td>
            </tr>
            <tr>
              <th>{t("Execution Date")}</th>
              <td>{order.lastUpdateDate}</td>
            </tr>
            <tr>
              <th>{t("Created By")}</th>
              <td>{order.created_by}</td>
            </tr>
            <tr>
              <th>{t("More Information")}</th>
              <td>
                <FaInfoCircle
                  size={16}
                  onClick={() => {
                    setShowInfoModal(true);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <OrderInformationModal
          showInfoModal={showInfoModal}
          setShowInfoModal={setShowInfoModal}
          order={order}
        />
      </Card.Body>
    </Card>
  );
};

export default Order;
