import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NotyfContext from "../../../../contexts/NotyfContext";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useWhitelabel } from "../../../../contexts/WhitelabelContext";
import { exportReport } from "../../../../redux/slices/reports";
import FileSaver from "file-saver";
import { usePagination, useTable } from "react-table";
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { DropdownSubmenu } from "react-bootstrap-submenu";

const OrdersTable = () => {
  const { t } = useTranslation();
  const orderReport = useSelector((state) => state.reports.orderReport);
  const { backgroundColor, fontColor } = useWhitelabel();
  const notyf = useContext(NotyfContext);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  const columns = useMemo(
    () => [
      {
        Header: t("reports.id"),
        accessor: "id",
      },
      {
        Header: t("reports.name"),
        accessor: "name",
      },
      {
        Header: t("reports.status"),
        accessor: "status",
      },
      {
        Header: t("reports.creator"),
        accessor: "created_by", // Fixed accessor
      },
      {
        Header: t("reports.creationDate"),
        accessor: "creationDate",
      },
    ],
    []
  );

  const exportToCSV = async () => {
    const headers = columns.map((col) => col.Header);
    const data = orderReport.map((row) =>
      columns.map((col) => row[col.accessor])
    );
    const format = "csv";
    await exportReport({ headers, data, format })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv",
        });
        FileSaver.saveAs(blob, "report.csv");
      })
      .catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          ripple: true,
          dismissible: false,
          duration: 5000,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
  };

  const exportToPDF = async () => {
    const headers = columns.map((col) => col.Header);
    const data = orderReport.map((row) =>
      columns.map((col) => row[col.accessor])
    );
    const format = "pdf";
    await exportReport({ headers, data, format })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        FileSaver.saveAs(blob, "report.pdf");
      })
      .catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          ripple: true,
          dismissible: false,
          duration: 5000,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: orderReport,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  if (isDesktop) {
    return (
      <React.Fragment>
        <Row>
          <Col className="ms-auto" md="auto">
            <DropdownButton
              as={ButtonGroup}
              style={{
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
                border: "none",
              }}
              title={t("reports.actions")}
            >
              <DropdownSubmenu title={t("reports.export")}>
                <Dropdown.Item eventKey="CSV" onClick={() => exportToCSV()}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item eventKey="PDF" onClick={() => exportToPDF()}>
                  PDF
                </Dropdown.Item>
              </DropdownSubmenu>
            </DropdownButton>
          </Col>
        </Row>
        <Table {...getTableProps()} striped hover responsive>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {orderReport.length > 0 && (
          <Row className="mt-4">
            <Col md="9">
              <span className="mx-2">
                {t("table.page")}{" "}
                <strong>
                  {pageIndex + 1} {t("table.of")} {pageOptions.length}
                </strong>
              </span>
              <span className="ms-3 me-2">{t("table.show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">{t("table.goToPage")}:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                defaultValue={pageIndex + 1}
                min={1}
                max={pageCount}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="3">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center mb-3">
          <DropdownButton
            as={ButtonGroup}
            style={{
              "--dynamic-bg-color": backgroundColor,
              "--dynamic-font-color": fontColor,
              border: "none",
              padding: "10px",
              width: "100%",
            }}
            title={t("reports.actions")}
          >
            <DropdownSubmenu title={t("reports.export")}>
              <Dropdown.Item eventKey="CSV" onClick={() => exportToCSV()}>
                CSV
              </Dropdown.Item>
              <Dropdown.Item eventKey="PDF" onClick={() => exportToPDF()}>
                PDF
              </Dropdown.Item>
            </DropdownSubmenu>
          </DropdownButton>
        </div>
        <Table {...getTableProps()} striped hover responsive>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {orderReport.length > 0 && (
          <>
            {isDesktop && (
              <Row className="mt-4">
                <Col md="9">
                  <span className="mx-2">
                    {t("table.page")}{" "}
                    <strong>
                      {pageIndex + 1} {t("table.of")} {pageOptions.length}
                    </strong>
                  </span>
                  <span className="ms-3 me-2">{t("table.show")}:</span>
                  <Form.Select
                    className="d-inline-block w-auto"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[5, 10, 20, 30, 40, 50].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </Form.Select>

                  <span className="ms-3 me-2">{t("table.goToPage")}:</span>
                  <Form.Control
                    className="d-inline-block"
                    type="number"
                    defaultValue={pageIndex + 1}
                    min={1}
                    max={pageCount}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "75px" }}
                  />
                </Col>
                <Col md="3">
                  <Pagination className="float-end">
                    <Pagination.First
                      onClick={() => gotoPage(0)}
                      disabled={!canPreviousPage}
                    />
                    <Pagination.Prev
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    />
                    <Pagination.Next
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    />
                    <Pagination.Last
                      onClick={() => gotoPage(pageCount - 1)}
                      disabled={!canNextPage}
                    />
                  </Pagination>
                </Col>
              </Row>
            )}
            {!isDesktop && (
              <React.Fragment>
                <div className="report-pagination mt-3">
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-center">
                      <Pagination className="float-end">
                        <Pagination.Prev
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        />
                        <Col className="d-flex align-items-end mx-2 px-3">
                          <span className="mx-2">
                            <strong>
                              {pageIndex + 1} {t("table.of")}{" "}
                              {pageOptions.length}
                            </strong>
                          </span>
                        </Col>
                        <Pagination.Next
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        />
                      </Pagination>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs="auto">
                      <span className="me-2">{t("table.show")}:</span>
                      <Form.Select
                        className="d-inline-block w-auto"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                        }}
                        style={{
                          padding: "0.25rem 2.1rem 0.25rem 0.7rem",
                          border: "none",
                          boxShadow: "none",
                          fontSize: "14px",
                        }}
                      >
                        {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <div className="float-end">
                        <span className="me-2">{t("table.goToPage")}:</span>
                        <Form.Control
                          className="d-inline-block w-auto"
                          type="number"
                          defaultValue={pageIndex + 1}
                          min={1}
                          max={pageCount}
                          onChange={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPage(page);
                          }}
                          style={{
                            padding: "0.25rem 1.2rem 0.25rem 0.7rem",
                            fontSize: "0.825rem",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            )}
          </>
        )}
      </React.Fragment>
    );
  }
};

export default OrdersTable;
