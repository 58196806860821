import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Table, Col, Form, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/spinners/Loader";
import { getSimsByNetwork } from "../../../../redux/slices/sims";
import { current } from "@reduxjs/toolkit";
import NotyfContext from "../../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";

const SimsByNetworkModal = ({
  showSimsByNetworkModal,
  setShowSimsByNetworkModal,
  network,
  country,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const simsByNetwork = useSelector((state) => state.sims.simsByNetwork);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchSimsByNetwork = async () => {
      await dispatch(
        getSimsByNetwork({
          page: currentPage,
          pageSize: pageSize,
          network: network,
          country: country,
          query: searchQuery,
        })
      )
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id && network && showSimsByNetworkModal) {
      fetchSimsByNetwork();
    }
  }, [activeUser, network, showSimsByNetworkModal, currentPage, pageSize]);

  return (
    <Modal
      show={showSimsByNetworkModal}
      onHide={() => setShowSimsByNetworkModal(false)}
      centered
      size="lg"
      backdrop="static"
      scrollable
    >
      <Modal.Header>
        <Col md={9}>SIMs By Network: {network}</Col>
        <Col md={3}>
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="my-3"
          />
        </Col>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <Loader />}
        {/* {!isLoading && simsByStatus.length === 0 && (
          <Table responsive hover>
            <thead>No data</thead>
          </Table>
        )} */}
        {!isLoading && simsByNetwork.sims && simsByNetwork.sims.length > 0 && (
          <>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>IMSI</th>
                  <th>ICCID</th>
                  <th>MSISDN</th>
                  <th>Offer</th>
                </tr>
              </thead>
              <tbody>
                {simsByNetwork.sims.map((session) => {
                  return (
                    <tr key={session.imsi}>
                      <td>{session.imsi}</td>
                      <td>{session.iccid}</td>
                      <td>{session.msisdn}</td>
                      <td>
                        {session.offer?.name ? session.offer?.name : "N/A"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span className="mx-2">
          {t("table.page")}{" "}
          <strong>
            {currentPage} {t("table.of")} {simsByNetwork.pages}
          </strong>
        </span>
        <span className="ms-3 me-2">{t("table.show")}:</span>
        <Form.Select
          className="d-inline-block w-auto"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageRecords) => (
            <option key={pageRecords} value={pageRecords}>
              {pageRecords}
            </option>
          ))}
        </Form.Select>
        <Pagination>
          <Pagination.First onClick={() => setCurrentPage(1)} />
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          <Pagination.Next
            onClick={() => {
              setCurrentPage((prev) => Math.min(prev + 1, simsByNetwork.pages));
            }}
            disabled={currentPage === simsByNetwork.pages}
          />
          <Pagination.Last
            onClick={() => setCurrentPage(simsByNetwork.pages)}
            disabled={currentPage === simsByNetwork.pages}
          />
        </Pagination>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          onClick={() => {
            setShowSimsByNetworkModal(false);
            setPageSize(10);
            setCurrentPage(1);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SimsByNetworkModal;
