import React from "react";
import { Form } from "react-bootstrap";

const NewSimForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
}) => {
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Sim Type</Form.Label>
        <Form.Select
          name="simType"
          value={values.simType}
          onChange={(e) => {
            setFieldValue("name", e.target.value);
            handleChange(e);
          }}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.simType && errors.simType)}
        >
          <option value="">Select SIM Card Type</option>
          <option value="T2">T2 Sim Card</option>
          <option value="T3">T3 Sim Card</option>
          <option value="Embedded">Embedded SIM Card</option>
          <option value="IoT Sim Card Industrial">
            IoT Sim Card Industrial
          </option>
          <option value="IoT Sim Chip Industrial">
            IoT Sim Chip Industrial
          </option>
        </Form.Select>
        {!!touched.simType && (
          <Form.Control.Feedback type="invalid">
            {errors.simType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Price</Form.Label>
        <Form.Control
          type="text"
          name="price"
          value={values.price}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.price && errors.price)}
        />
        {!!touched.price && (
          <Form.Control.Feedback type="invalid">
            {errors.price}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>VAT(%)</Form.Label>
        <Form.Control
          type="text"
          value={values.vat}
          name="vat"
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.vat && errors.vat)}
        />
        {!!touched.vat && (
          <Form.Control.Feedback type="invalid">
            {errors.vat}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Unit Type</Form.Label>
        <Form.Select
          name="unitType"
          value={values.unitType}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={Boolean(touched.unitType && errors.unitType)}
        >
          <option value="">Select Unit Type</option>
          <option value="second">Second</option>
          <option value="minute">Minute</option>
          <option value="hour">Hour</option>
          <option value="day">Day</option>
          <option value="week">Week</option>
          <option value="month">Month</option>
        </Form.Select>
        {!!touched.unitType && (
          <Form.Control.Feedback type="invalid">
            {errors.unitType}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </>
  );
};

export default NewSimForm;
