import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Modal, Table, Col, Form, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/spinners/Loader";
import {
  getSimCount,
  getSimInventoryCount,
  getSimsByStatus,
} from "../../../../redux/slices/sims";
import NotyfContext from "../../../../contexts/NotyfContext";
import { useTranslation } from "react-i18next";

const SimsByStatusModal = ({
  showSimsByStatusModal,
  setShowSimsByStatusModal,
  status,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const [activeUser, setActiveUser] = useState({});
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const simsByStatus = useSelector((state) => state.sims.simsByStatus);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();
  const count = useSelector((state) => state.sims.count);

  useEffect(() => {
    setTotalPages(Math.ceil(count / pageSize));
  }, [count, pageSize]);

  useEffect(() => {
    setActiveUser(impersonationUser.id ? impersonationUser : user);
  }, [user, impersonationUser]);

  useEffect(() => {
    const fetchSimsByStatus = async () => {
      await Promise.all([
        dispatch(
          getSimsByStatus({
            page: currentPage,
            pageSize: pageSize,
            status: status,
            query: searchQuery,
          })
        ),
      ])
        .catch((error) => {
          notyf.open({
            type: "danger",
            message: error,
            duration: 5000,
            dismissible: false,
            ripple: true,
            position: {
              x: "center",
              y: "top",
            },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (activeUser.id && status) {
      fetchSimsByStatus();
    }
  }, [activeUser, status, currentPage, pageSize, searchQuery]);

  // if (!Array.isArray(simsByStatus)) {
  //   console.error("simsByStatus is not an array:", simsByStatus);
  // }

  return (
    <Modal
      show={showSimsByStatusModal}
      onHide={() => setShowSimsByStatusModal(false)}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header>
        <Col md={9}>SIMs By Status: {status}</Col>
        <Col md={3}>
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="my-3"
          />
        </Col>
      </Modal.Header>
      <Modal.Body style={{ height: "600px", overflowY: "auto" }}>
        {isLoading && <Loader />}
        {!isLoading && simsByStatus.sims && simsByStatus.sims.length > 0 && (
          <>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>IMSI</th>
                  <th>ICCID</th>
                  <th>MSISDN</th>
                  <th>Offer</th>
                </tr>
              </thead>
              <tbody>
                {simsByStatus.sims.map((session) => {
                  return (
                    <tr key={session.imsi}>
                      <td>{session.imsi}</td>
                      <td>{session.iccid}</td>
                      <td>{session.msisdn}</td>
                      <td>
                        {session?.offer?.name ? session.offer.name : "N/A"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span className="mx-2">
          {t("table.page")}{" "}
          <strong>
            {currentPage} {t("table.of")} {simsByStatus.pages}
          </strong>
        </span>
        <span className="ms-3 me-2">{t("table.show")}:</span>
        <Form.Select
          className="d-inline-block w-auto"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageRecords) => (
            <option key={pageRecords} value={pageRecords}>
              {pageRecords}
            </option>
          ))}
        </Form.Select>
        <Pagination>
          <Pagination.First onClick={() => setCurrentPage(1)} />
          <Pagination.Prev
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          <Pagination.Next
            onClick={() => {
              setCurrentPage((prev) => Math.min(prev + 1, simsByStatus.pages));
            }}
            disabled={currentPage === simsByStatus.pages}
          />
          <Pagination.Last onClick={() => setCurrentPage(simsByStatus.pages)} />
        </Pagination>
        <Button
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
          onClick={() => {
            setShowSimsByStatusModal(false);
            setPageSize(10);
            setCurrentPage(1);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SimsByStatusModal;
