import {
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useWhitelabel } from "../../../contexts/WhitelabelContext";
import NotyfContext from "../../../contexts/NotyfContext";
import React, { useContext, useMemo, useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { t } from "i18next";
import { exportReport } from "../../../redux/slices/reports";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import FileSaver from "file-saver";
import { usePagination, useTable } from "react-table";

const IPAllocationTable = () => (
  <Table striped hover responsive>
    <thead>
      <tr>
        <th>Customer</th>
        <th>Allocation Type</th>
        <th>Start IP</th>
        <th>End IP</th>
        <th>IPs Count</th>
        <th>IP Type</th>
        <th>IP Configuration Name</th>
        <th>Operator</th>
        <th>Customer Subnet</th>
        <th>Domain Subnet</th>
      </tr>
    </thead>
    <tbody></tbody>
  </Table>
);

const InvoiceTable = () => {
  const invoiceReport = useSelector((state) => state.reports.invoiceReport);
  const { backgroundColor, fontColor } = useWhitelabel();
  const notyf = useContext(NotyfContext);
  const [width] = useWindowSize();
  const isDesktop = width > 768;

  const columns = useMemo(
    () => [
      {
        Header: t("reports.imsi"),
        accessor: "imsi",
      },
      {
        Header: t("reports.description"),
        accessor: "description",
      },
      {
        Header: t("reports.quantity"),
        accessor: "quantity",
      },
      {
        Header: t("reports.unitPrice"),
        accessor: "unitPrice", // Fixed accessor
      },
      {
        Header: t("reports.tax"),
        accessor: "tax",
      },
      {
        Header: t("reports.costWithoutTax"),
        accessor: "costWithoutTax",
      },
      {
        Header: t("reports.costWithTax"),
        accessor: "costWithTax",
      },
      {
        Header: t("reports.currency"),
        accessor: "currency",
      },
      {
        Header: t("reports.offerName"),
        accessor: "offerName",
      },
    ],
    []
  );

  const exportToCSV = async () => {
    const headers = columns.map((col) => col.Header);
    const data = invoiceReport.map((row) =>
      columns.map((col) => row[col.accessor])
    );
    const format = "csv";
    await exportReport({ headers, data, format })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "text/csv",
        });
        FileSaver.saveAs(blob, "report.csv");
      })
      .catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          ripple: true,
          dismissible: false,
          duration: 5000,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
  };

  const exportToPDF = async () => {
    const headers = columns.map((col) => col.Header);
    const data = invoiceReport.map((row) =>
      columns.map((col) => row[col.accessor])
    );
    const format = "pdf";
    await exportReport({ headers, data, format })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        FileSaver.saveAs(blob, "report.pdf");
      })
      .catch((error) => {
        notyf.open({
          type: "danger",
          message: error,
          ripple: true,
          dismissible: false,
          duration: 5000,
          position: {
            x: "center",
            y: "top",
          },
        });
      });
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: columns,
      data: invoiceReport,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  if (isDesktop) {
    return (
      <React.Fragment>
        <Row>
          <Col className="ms-auto" md="auto">
            <DropdownButton
              as={ButtonGroup}
              style={{
                "--dynamic-bg-color": backgroundColor,
                "--dynamic-font-color": fontColor,
                border: "none",
              }}
              title={t("reports.actions")}
            >
              <DropdownSubmenu title={t("reports.export")}>
                <Dropdown.Item eventKey="CSV" onClick={() => exportToCSV()}>
                  CSV
                </Dropdown.Item>
                <Dropdown.Item eventKey="PDF" onClick={() => exportToPDF()}>
                  PDF
                </Dropdown.Item>
              </DropdownSubmenu>
            </DropdownButton>
          </Col>
        </Row>
        <Table {...getTableProps()} striped hover responsive>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {invoiceReport.length > 0 && (
          <Row className="mt-4">
            <Col md="9">
              <span className="mx-2">
                {t("table.page")}{" "}
                <strong>
                  {pageIndex + 1} {t("table.of")} {pageOptions.length}
                </strong>
              </span>
              <span className="ms-3 me-2">{t("table.show")}:</span>
              <Form.Select
                className="d-inline-block w-auto"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Select>

              <span className="ms-3 me-2">{t("table.goToPage")}:</span>
              <Form.Control
                className="d-inline-block"
                type="number"
                defaultValue={pageIndex + 1}
                min={1}
                max={pageCount}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "75px" }}
              />
            </Col>
            <Col md="3">
              <Pagination className="float-end">
                <Pagination.First
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                />
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
                <Pagination.Last
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
};

const SimTable = () => (
  <Table striped hover>
    <thead>
      <tr>
        <th>ICCID</th>
        <th>IMSI</th>
        <th>MSISDN</th>
        <th>IP Address</th>
        <th>Platform</th>
        <th>Manager IP</th>
        <th>User IP</th>
        <th>User Email</th>
        <th>User Name</th>
        <th>Status</th>
        <th>Activation Date</th>
        <th>Offer</th>
      </tr>
    </thead>
    <tbody></tbody>
  </Table>
);

export { InvoiceTable, SimTable, IPAllocationTable };
