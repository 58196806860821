function getCurrencySymbol(currencyCode) {
  const cc = require("currency-codes");
  const currency = cc.code(currencyCode);
  if (!currency) return null;

  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: currencyCode,
  })
    .formatToParts(1)
    .find((part) => part.type === "currency")?.value;
}

const getBillingProductsColumns = (t) => {
  const billingProductsColumns = [
    {
      Header: t("billingProducts.name"),
      accessor: "offerItem.name",
      sortable: true,
    },
    {
      Header: t("billingProducts.pricePlan"),
      accessor: "offer.name",
      sortable: true,
    },
    {
      Header: t("billingProducts.price"),
      accessor: "price",
      sortable: true,
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.price}
            {getCurrencySymbol(original.offer.currency)}/
            {original.offerItem.unitType}
          </>
        );
      },
    },
  ];

  return { billingProductsColumns };
};

export default getBillingProductsColumns;
