import { Modal, Table, Button } from "react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const OrderModal = ({ showOrderModal, setShowOrderModal, orders }) => {
  const { user } = useSelector((state) => state.user);
  const { impersonationUser } = useSelector((state) => state.impersonationUser);
  const whitelabel = useSelector((state) => state.whitelabel.whitelabel);
  const { t } = useTranslation();

  return (
    <Modal
      centered
      scrollable
      backdrop="static"
      show={showOrderModal}
      onHide={() => setShowOrderModal(false)}
      style={{ maxHeight: "70%" }}
    >
      <Modal.Header>Orders</Modal.Header>
      <Modal.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>{t("ID")}</th>
              <th>{t("Name")}</th>
              <th>{t("Creation Date")}</th>
              <th>{t("Execution Date")}</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => {
              return (
                <tr key={index}>
                  <td>{order.id}</td>
                  <td>{order.name}</td>
                  <td>{order.creationDate}</td>
                  <td>{order.lastUpdateDate}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => setShowOrderModal(false)}
          style={{
            "--dynamic-bg-color": whitelabel?.backgroundColor ?? "#00acdc",
            "--dynamic-font-color": whitelabel?.fontColor ?? "#ffffff",
            border: "none",
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderModal;
